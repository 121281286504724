<!--  -->
<template>
  <div class="home">
    <Header></Header>
    <div class="">
      <Menus />
    </div>

    <div>
      <!-- <router-view /> -->
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header/index.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
}
</script>
<style lang='scss' scoped>
.home {
  width: 100%;
  height: 100%;
}
</style>