<template>
  <header class="header flex flex-a-c">
    <div class="flex flex-j-s-b flex-a-e" style="width: 100%">
      <div class="header-time">
        <span class="font-s-16 font-w-500">{{ nowDate }}</span>
        <span class="font-s-16 font-w-500">{{ nowTime }}</span>
        <span class="font-s-16 font-w-500">{{ nowWeek }}</span>
        <span class="font-s-16 font-w-500 header-refresh pointer" @click="Reload">
          <img src="../../assets/images/refresh@2x.png" />
          <span class="font-s-16 font-w-500">刷新</span></span>
      </div>

      <div class="flex flex-a-e" style="margin-left:calc(38/1920*100vw)">
        <h1 class="font-s-36 font-w-b">智慧水利综合管理平台</h1>
        <span class="subtitle">{{ subtitle }}</span>
      </div>
      <div class="flex">
        <div class="header-action flex flex-a-e pointer">
          <img src="../../assets/images/admin@2x.png" />
          <span class="font-s-16 font-w-500">欢迎您，{{ userName }}</span>
          <div class="bottom"></div>
        </div>
        <div class="header-action flex flex-a-e pointer" @click="fullScreen()">
          <img src="../../assets/images/all@2x.png" />
          <span class="font-s-16 font-w-500">全屏</span>
        </div>

        <div class="header-action flex flex-a-e pointer" @click="exit">
          <img src="../../assets/images/out@2x.png" />
          <span class="font-s-16 font-w-500">退出</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { getNowDayFn, getNowTime, getWeekDate } from "../../utils/methods";
import { logout } from "../../api/login";
export default {
  inject: ["reload"],
  name: "",
  props: {
    navItems: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      userName: "",
      nowDate: "", //当前日期
      nowTime: "", //当前时间
      nowWeek: "", //获取当前星期
      subtitle: ""
    };
  },
  watch: {
    navItems(e) {
      for (const i of e) {
        if (i.children) {
          for (const l of i.children) {
            if (l.routeName === this.$route.path) {
              this.subtitle = `— ${l.moduleName}`;
            }
          }
        } else {
          if (i.routeName === this.$route.path) {
            this.subtitle = `— ${i.moduleName}`;
          }
        }
      }
    }
  },
  created() {
    this.nowDate = getNowDayFn();
    this.nowTime = getNowTime();
    this.nowWeek = getWeekDate();
    setInterval(() => {
      this.nowTime = getNowTime();
    }, 1000);
    this.userName = window.localStorage.getItem("userName");
  },
  mounted() {
    for (const i of this.navItems) {
      if (i.children) {
        for (const l of i.children) {
          if (l.routeName === this.$route.path) {
            this.subtitle = `— ${l.moduleName}`;
          }
        }
      } else {
        if (i.routeName === this.$route.path) {
          this.subtitle = `— ${i.moduleName}`;
        }
      }
    }
  },
  destroyed() {
    // getDateTimer.terminate();
  },
  methods: {
    // 刷新
    Reload() {
      this.reload();
    },
    // 全屏
    fullScreen() {
      document.webkitExitFullscreen();
      document.documentElement.requestFullscreen();
    },
    // 退出
    async exit() {
      try {
        await this.$confirm("是否退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });
        const id = localStorage.getItem("id");
        logout({ id: id }).then(res => {
          if (res.code == 200) {
            localStorage.clear();
            localStorage.setItem("remember", false);
            window.location.href = "/";
            // this.$router.push({ path: "/" });
            this.$message.success("退出成功");
            // localStorage.clear();
            // localStorage.setItem("remember", false);
          } else {
            this.$message.error("服务器异常");
          }
        });
      } catch { }
    }
  }
};
</script>

<style lang="scss" scoped>
.subtitle {
  margin-left: calc(23 / 1920 * 100vw);
  font-size: calc(36 / 1920 * 100vw);
  font-weight: bold;
}
.header {
  height: calc(90 / 1080 * 100vh);
  padding: 0 calc(25 / 1920 * 100vw);
  background: linear-gradient(0deg, #295899, #3467a9, #4579bc, #5994d3);
  box-shadow: 0px 9px 27px 0px rgba(84, 115, 235, 0.1);
}
.header-time > span + span {
  margin-left: calc(15 / 1920 * 100vw);
}

.header-action > img {
  width: calc(24 / 1920 * 100vw);
  margin-right: calc(6 / 1920 * 100vw);
}
.header-refresh > img {
  width: calc(24 / 1920 * 100vw);
  height: calc(24 / 1920 * 100vw);
  position: relative;
  top: calc(12 / 1920 * 100vh);
  margin-right: calc(6 / 1920 * 100vw);
}
.header-action + .header-action {
  margin-left: calc(38 / 1920 * 100vw);
}
.bottom {
  position: relative;
  bottom: calc(10 / 1920 * 100vh);
  left: calc(3 / 1920 * 100vw);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #ffffff;
  border-radius: 7px;
}
</style>
